<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="1024"
        scrollable
    >
        <v-card>
            <v-card-title class="tw-flex tw-items-center tw-justify-between">
                <div>{{ service.name }} usage</div>
                <div class="tw-flex tw-items-center">
                    <div style="width: 225px;">
                        <v-menu
                            ref="usagePeriodMenu"
                            v-model="usagePeriodMenu"
                            :close-on-content-click="false"
                            :return-value.sync="usagePeriodDates"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="usagePeriodDatesText"
                                    label="Date range"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="usagePeriodDates"
                                no-title
                                scrollable
                                range
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="usagePeriodMenu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="changeDateRange"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                    <v-btn icon class="tw-ml-4" @click="close">
                        <v-icon>fal fa-times</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <div class="tw-grid tw-gap-4 md:tw-grid-cols-2 tw-p-4">
                <v-card>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{ meta.total_duration_for_human }}</v-list-item-title>
                            <v-list-item-subtitle>Total duration</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <v-card>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title class="headline">{{ meta.total_charge_for_human }}</v-list-item-title>
                            <v-list-item-subtitle>Total charge</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </div>
            <DxDataGrid
                :show-borders="false"
                :show-row-lines="true"
                :show-column-lines="false"
                :data-source="cdr"
                :remote-operations="true"
                height="calc(100vh - 302px)"
                @toolbar-preparing="onToolbarPreparing"
                @initialized="handleDataGridInitialized"
                width="100%"
            >
                <DxSorting mode="multiple" />
                <DxHeaderFilter :visible="false" />
                <DxFilterRow :visible="false" />

                <DxColumn
                    caption="From"
                    data-field="from"
                    data-type="string"
                    :allow-sorting="false"
                />
                <DxColumn
                    caption="To"
                    data-field="to"
                    width="auto"
                    :allow-sorting="false"
                />
                <DxColumn
                    caption="Start"
                    data-field="start_at_for_human"
                    width="auto"
                    :allow-sorting="false"
                />
                <DxColumn
                    caption="End"
                    data-field="end_at_for_human"
                    data-type="string"
                    width="auto"
                    :allow-sorting="false"
                />
                <DxColumn
                    caption="Duration"
                    data-field="billable_duration_for_human"
                    data-type="string"
                    width="auto"
                    :allow-sorting="false"
                    :allow-filtering="false"
                />
                <DxColumn
                    caption="Charge"
                    data-field="charge_for_human"
                    data-type="number"
                    width="auto"
                    :allow-sorting="false"
                    :allow-filtering="false"
                />         
                <DxColumn
                    caption="Rated"
                    data-field="is_rated"
                    data-type="number"
                    width="auto"
                    cell-template="rated"
                    :allow-sorting="false"
                    :allow-filtering="false"
                />         
                <DxScrolling row-rendering-mode="virtual"/>
                <DxPaging :page-size="100"/>
                <DxPager
                    :visible="true"
                    :show-page-size-selector="true"
                    :show-info="true"
                    :allowed-page-sizes="[15, 50, 100]"
                />
                <DxSearchPanel :visible="true" />
                <template #tableTitle>
                    <h1 class="tw-text-base tw-font-semibold tw-tracking-normal">CDR</h1>
                </template>
                <template #rated="{ data: { data: item } }">
                    <v-icon v-if="item.is_rated === true" color="success" x-small>fal fa-check</v-icon>
                    <v-icon v-else color="error" x-small>fal fa-times-circle</v-icon>
                </template>       
                <template #refreshButton>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                aria-label="Refresh table"
                                @click="refreshTable"
                            >
                                <v-icon x-small>fal fa-sync</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh table</span>
                    </v-tooltip>
                </template>
            </DxDataGrid>
        </v-card>
    </v-dialog>
</template>

<script>
    import { 
        DxDataGrid, 
        DxColumn, 
        DxPaging, 
        DxPager,
        DxScrolling,
        DxHeaderFilter,
        DxFilterRow,
        DxSearchPanel,
        DxSorting,
    } from 'devextreme-vue/data-grid';
    import '@/plugins/devexpress/dx.material.md-scheme-compact.css';
    import CustomStore from 'devextreme/data/custom_store';
    import moment from 'moment';
    import Http from '@/utils/httpClient';

    export default {
        components: {
            DxDataGrid,
            DxColumn,
            DxPaging,
            DxPager,
            DxScrolling,
            DxHeaderFilter,
            DxFilterRow,
            DxSearchPanel,
            DxSorting,
        },
        data() {
            return {
                show: false,
                usagePeriodMenu: false,
                usagePeriodDates: null,
                isTableLoading: false,
                dataGridInstance: null,
                service: {},
                meta: {},
                cdr: null,
            }
        },
        computed: {
            usagePeriodDatesText() {
                return this.usagePeriodDates.join(' ~ ');
            }
        },
        watch: {
            $route() {
                this.init();
            },
            isTableLoading(isLoading) {                
                if (this.dataGridInstance === null) {
                    return;
                }

                if (isLoading) {
                    this.dataGridInstance.beginCustomLoading();
                } else {
                    this.dataGridInstance.endCustomLoading();
                }
            },
        },
        mounted() {
            this.init();
            this.usagePeriodDates = [
                moment().format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD'),
            ];
        },
        methods: {
            init() {
                if (this.show === true) {
                    return;
                }

                if (
                    this.$route.name === 'services.voice.usage'
                ) {
                    this.open();
                }
            },
            open() {
                this.show = true;
                this.cdr = new CustomStore({
                    key: 'id',
                    load: async (options) => {
                        const per_page = options.take;
                        const page = options.skip === 0 ? 1 : (options.skip / options.take) + 1;

                        this.isTableLoading = true;

                        try {
                            const { data } = await Http.get(`companies/services/${this.$route.params.serviceId}/usage`, {
                                params: {
                                    per_page,
                                    page,
                                    'start-date': this.usagePeriodDates[0],
                                    'end-date': this.usagePeriodDates[1],
                                    filter: options.filter,
                                    sorter: options.sort,
                                }
                            });  
                            
                            this.service = data.company_service;
                            this.meta = data.meta;

                            return {
                                data: data.cdr,
                                totalCount: data.meta.total
                            }
                        } finally {
                            this.isTableLoading = false;
                        }        
                    }
                })
            },
            close() {
                this.show = false;
                this.$router.push({
                    name: this.$route.meta.parent,
                });
            },
            refreshTable() {
                this.dataGridInstance.refresh();
            },
            onToolbarPreparing(e) {
                const { items } = e.toolbarOptions;
                console.log(e)
                items.push({
                    location: 'before',
                    template: 'tableTitle',
                })

                items.push({
                    location: 'after',
                    template: 'refreshButton',
                })
            },
            handleDataGridInitialized(e) {
                this.dataGridInstance = e.component;
            },
            changeDateRange() {
                this.$refs.usagePeriodMenu.save(this.usagePeriodDates);
                this.refreshTable();
            }
        }
    }
</script>
